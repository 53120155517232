import { strings } from "../../localization/strings";
import { getEnumValidNumberKeys, range } from "../helpers/common";
import { VerifyChecks, DiagnosticSets, DiagnosticCases, VerifyStatuses, definitions } from '@piceasoft/core';
import { ContentType } from "./picea.typings";

/* Currently not used
export const setsDescriber = (id: number) => {
    switch (id) {
        case DiagnosticSets.TS_ACCESSORIES: return strings.PICEA.DIAGNOSTICS.SETS[176] as string;
        case DiagnosticSets.TS_AUDIO: return strings.PICEA.DIAGNOSTICS.SETS[16] as string;
        case DiagnosticSets.TS_BATTERY: return strings.PICEA.DIAGNOSTICS.SETS[144] as string;
        case DiagnosticSets.TS_CAMERA: return strings.PICEA.DIAGNOSTICS.SETS[32] as string;
        case DiagnosticSets.TS_CHIPSETS: return strings.PICEA.DIAGNOSTICS.SETS[208] as string;
        case DiagnosticSets.TS_CONDITION: return strings.PICEA.DIAGNOSTICS.SETS[160] as string;
        case DiagnosticSets.TS_CONNECTIVITY: return strings.PICEA.DIAGNOSTICS.SETS[80] as string;
        case DiagnosticSets.TS_CONNECTORS: return strings.PICEA.DIAGNOSTICS.SETS[112] as string;
        case DiagnosticSets.TS_DISPLAY: return strings.PICEA.DIAGNOSTICS.SETS[48] as string;
        case DiagnosticSets.TS_GENERAL: return strings.PICEA.DIAGNOSTICS.SETS[0] as string;
        case DiagnosticSets.TS_HW_BUTTONS: return strings.PICEA.DIAGNOSTICS.SETS[64] as string;
        case DiagnosticSets.TS_PERFORMANCE: return strings.PICEA.DIAGNOSTICS.SETS[192] as string;
        case DiagnosticSets.TS_ROBOTIC: return strings.PICEA.DIAGNOSTICS.SETS[224] as string;
        case DiagnosticSets.TS_SENSORS: return strings.PICEA.DIAGNOSTICS.SETS[96] as string;
        case DiagnosticSets.TS_SOFTWARE: return strings.PICEA.DIAGNOSTICS.SETS[128] as string;
        default: return id.toString();
    }
}*/

export const testDescriber = (id: number) => {
    switch (id) {
        case 0: return strings.PICEA.DIAGNOSTICS.CASES[0] as string;
        case 1: return strings.PICEA.DIAGNOSTICS.CASES[1] as string;
        case 2: return strings.PICEA.DIAGNOSTICS.CASES[2] as string;
        case 3: return strings.PICEA.DIAGNOSTICS.CASES[3] as string;
        case 4: return strings.PICEA.DIAGNOSTICS.CASES[4] as string;
        case 5: return strings.PICEA.DIAGNOSTICS.CASES[5] as string;
        case 6: return strings.PICEA.DIAGNOSTICS.CASES[6] as string;
        case 7: return strings.PICEA.DIAGNOSTICS.CASES[7] as string;
        case 8: return strings.PICEA.DIAGNOSTICS.CASES[8] as string;
        case 9: return strings.PICEA.DIAGNOSTICS.CASES[9] as string;
        case 10: return strings.PICEA.DIAGNOSTICS.CASES[10] as string;
        case 16: return strings.PICEA.DIAGNOSTICS.CASES[16] as string;
        case 17: return strings.PICEA.DIAGNOSTICS.CASES[17] as string;
        case 18: return strings.PICEA.DIAGNOSTICS.CASES[18] as string;
        case 19: return strings.PICEA.DIAGNOSTICS.CASES[19] as string;
        case 32: return strings.PICEA.DIAGNOSTICS.CASES[32] as string;
        case 33: return strings.PICEA.DIAGNOSTICS.CASES[33] as string;
        case 34: return strings.PICEA.DIAGNOSTICS.CASES[34] as string;
        case 35: return strings.PICEA.DIAGNOSTICS.CASES[35] as string;
        case 36: return strings.PICEA.DIAGNOSTICS.CASES[36] as string;
        case 37: return strings.PICEA.DIAGNOSTICS.CASES[37] as string;
        case 38: return strings.PICEA.DIAGNOSTICS.CASES[38] as string;
        case 48: return strings.PICEA.DIAGNOSTICS.CASES[48] as string;
        case 49: return strings.PICEA.DIAGNOSTICS.CASES[49] as string;
        case 50: return strings.PICEA.DIAGNOSTICS.CASES[50] as string;
        case 51: return strings.PICEA.DIAGNOSTICS.CASES[51] as string;
        case 52: return strings.PICEA.DIAGNOSTICS.CASES[52] as string;
        case 53: return strings.PICEA.DIAGNOSTICS.CASES[53] as string;
        case 54: return strings.PICEA.DIAGNOSTICS.CASES[54] as string;
        case 64: return strings.PICEA.DIAGNOSTICS.CASES[64] as string;
        case 65: return strings.PICEA.DIAGNOSTICS.CASES[65] as string;
        case 66: return strings.PICEA.DIAGNOSTICS.CASES[66] as string;
        case 67: return strings.PICEA.DIAGNOSTICS.CASES[67] as string;
        case 68: return strings.PICEA.DIAGNOSTICS.CASES[68] as string;
        case 69: return strings.PICEA.DIAGNOSTICS.CASES[69] as string;
        case 70: return strings.PICEA.DIAGNOSTICS.CASES[70] as string;
        case 71: return strings.PICEA.DIAGNOSTICS.CASES[71] as string;
        case 72: return strings.PICEA.DIAGNOSTICS.CASES[72] as string;
        case 80: return strings.PICEA.DIAGNOSTICS.CASES[80] as string;
        case 81: return strings.PICEA.DIAGNOSTICS.CASES[81] as string;
        case 82: return strings.PICEA.DIAGNOSTICS.CASES[82] as string;
        case 83: return strings.PICEA.DIAGNOSTICS.CASES[83] as string;
        case 84: return strings.PICEA.DIAGNOSTICS.CASES[84] as string;
        case 85: return strings.PICEA.DIAGNOSTICS.CASES[85] as string;
        case 86: return strings.PICEA.DIAGNOSTICS.CASES[86] as string;
        case 87: return strings.PICEA.DIAGNOSTICS.CASES[87] as string;
        case 88: return strings.PICEA.DIAGNOSTICS.CASES[88] as string;
        case 96: return strings.PICEA.DIAGNOSTICS.CASES[96] as string;
        case 97: return strings.PICEA.DIAGNOSTICS.CASES[97] as string;
        case 98: return strings.PICEA.DIAGNOSTICS.CASES[98] as string;
        case 99: return strings.PICEA.DIAGNOSTICS.CASES[99] as string;
        case 100: return strings.PICEA.DIAGNOSTICS.CASES[100] as string;
        case 101: return strings.PICEA.DIAGNOSTICS.CASES[101] as string;
        case 102: return strings.PICEA.DIAGNOSTICS.CASES[102] as string;
        case 103: return strings.PICEA.DIAGNOSTICS.CASES[103] as string;
        case 105: return strings.PICEA.DIAGNOSTICS.CASES[105] as string;
        case 112: return strings.PICEA.DIAGNOSTICS.CASES[112] as string;
        case 113: return strings.PICEA.DIAGNOSTICS.CASES[113] as string;
        case 114: return strings.PICEA.DIAGNOSTICS.CASES[114] as string;
        case 115: return strings.PICEA.DIAGNOSTICS.CASES[115] as string;
        case 128: return strings.PICEA.DIAGNOSTICS.CASES[128] as string;
        case 129: return strings.PICEA.DIAGNOSTICS.CASES[129] as string;
        case 130: return strings.PICEA.DIAGNOSTICS.CASES[130] as string;
        case 131: return strings.PICEA.DIAGNOSTICS.CASES[131] as string;
        case 132: return strings.PICEA.DIAGNOSTICS.CASES[132] as string;
        case 133: return strings.PICEA.DIAGNOSTICS.CASES[133] as string;
        case 134: return strings.PICEA.DIAGNOSTICS.CASES[134] as string;
        case 135: return strings.PICEA.DIAGNOSTICS.CASES[135] as string;
        case 136: return strings.PICEA.DIAGNOSTICS.CASES[136] as string;
        case 137: return strings.PICEA.DIAGNOSTICS.CASES[137] as string;
        case 138: return strings.PICEA.DIAGNOSTICS.CASES[138] as string;
        case 144: return strings.PICEA.DIAGNOSTICS.CASES[144] as string;
        case 145: return strings.PICEA.DIAGNOSTICS.CASES[145] as string;
        case 146: return strings.PICEA.DIAGNOSTICS.CASES[146] as string;
        case 160: return strings.PICEA.DIAGNOSTICS.CASES[160] as string;
        case 161: return strings.PICEA.DIAGNOSTICS.CASES[161] as string;
        case 162: return strings.PICEA.DIAGNOSTICS.CASES[162] as string;
        case 163: return strings.PICEA.DIAGNOSTICS.CASES[163] as string;
        case 164: return strings.PICEA.DIAGNOSTICS.CASES[164] as string;
        case 165: return strings.PICEA.DIAGNOSTICS.CASES[165] as string;
        case 166: return strings.PICEA.DIAGNOSTICS.CASES[166] as string;
        case 176: return strings.PICEA.DIAGNOSTICS.CASES[176] as string;
        case 177: return strings.PICEA.DIAGNOSTICS.CASES[177] as string;
        case 178: return strings.PICEA.DIAGNOSTICS.CASES[178] as string;
        case 179: return strings.PICEA.DIAGNOSTICS.CASES[179] as string;
        case 180: return strings.PICEA.DIAGNOSTICS.CASES[180] as string;
        case 181: return strings.PICEA.DIAGNOSTICS.CASES[181] as string;
        case 182: return strings.PICEA.DIAGNOSTICS.CASES[182] as string;
        case 183: return strings.PICEA.DIAGNOSTICS.CASES[183] as string;
        case 184: return strings.PICEA.DIAGNOSTICS.CASES[184] as string;
        case 192: return strings.PICEA.DIAGNOSTICS.CASES[192] as string;
        case 193: return strings.PICEA.DIAGNOSTICS.CASES[193] as string;
        case 194: return strings.PICEA.DIAGNOSTICS.CASES[194] as string;
        case 195: return strings.PICEA.DIAGNOSTICS.CASES[195] as string;
        case 196: return strings.PICEA.DIAGNOSTICS.CASES[196] as string;
        case 208: return strings.PICEA.DIAGNOSTICS.CASES[208] as string;
        case 209: return strings.PICEA.DIAGNOSTICS.CASES[209] as string;
        case 210: return strings.PICEA.DIAGNOSTICS.CASES[210] as string;
        case 211: return strings.PICEA.DIAGNOSTICS.CASES[211] as string;
        case 212: return strings.PICEA.DIAGNOSTICS.CASES[212] as string;
        case 213: return strings.PICEA.DIAGNOSTICS.CASES[213] as string;
        case 214: return strings.PICEA.DIAGNOSTICS.CASES[214] as string;
        case 224: return strings.PICEA.DIAGNOSTICS.CASES[224] as string;
        default: return id.toString();
    }
}

export function verifyDescriber(check: number): string {
    switch (check) {
        case VerifyChecks.SECURITY_LOCK: return strings.PICEA.VERIFY.CHECKS.SECURITY_LOCK as string;
        case VerifyChecks.MEMORY_CARD: return strings.PICEA.VERIFY.CHECKS.SD_CARD as string;
        case VerifyChecks.SIM_CARD: return strings.PICEA.VERIFY.CHECKS.SIM_CARD as string;
        case VerifyChecks.PROTECTION: return strings.PICEA.VERIFY.CHECKS.GOOGLE_SECURITY as string;
        case VerifyChecks.FIND_MY: return strings.PICEA.VERIFY.CHECKS.FIND_IPHONE as string;
        case VerifyChecks.GSMA_DEVICE_CHECK: return strings.PICEA.VERIFY.CHECKS.GSMA_DEVICE_CKECK as string;
        case VerifyChecks.ACCOUNTS: return strings.PICEA.VERIFY.CHECKS.ACCOUNTS as string;
        case VerifyChecks.ROOTED: return strings.PICEA.VERIFY.CHECKS.ROOT as string;
        case VerifyChecks.CARRIER_LOCK: return strings.PICEA.VERIFY.CHECKS.OPERATOR_LOCK as string;
        case VerifyChecks.GENUINENESS: return strings.PICEA.VERIFY.CHECKS.AUTHENTICATION as string;
        case VerifyChecks.BATTERY_GENUINENESS: return strings.PICEA.VERIFY.CHECKS.BATTERY_GENUINE as string;
        case VerifyChecks.ESIM: return strings.PICEA.VERIFY.CHECKS.ESIM as string;
        case VerifyChecks.WARRANTY: return strings.PICEA.VERIFY.CHECKS.WARRANTY_STATUS as string;
        case VerifyChecks.DEP: return strings.PICEA.VERIFY.CHECKS.DEVICE_ENROLLMENT as string;
        case VerifyChecks.JAILBREAK: return strings.PICEA.VERIFY.CHECKS.JAILBRAKE as string;
        case VerifyChecks.XIAOMI_ACCOUNT: return strings.PICEA.VERIFY.CHECKS.XIAOMI_ACCOUNT as string;
        case VerifyChecks.GOOGLE_ACCOUNT: return strings.PICEA.VERIFY.CHECKS.GOOGLE_ACCOUNT as string;
        case VerifyChecks.KNOX: return strings.PICEA.VERIFY.CHECKS.SAMSUNG_KNOX as string;
        case VerifyChecks.SAMSUNG_ACCOUNT: return strings.PICEA.VERIFY.CHECKS.SAMSUNG_ACCOUNT as string;
        case VerifyChecks.HUAWEI_ACCOUNT: return strings.PICEA.VERIFY.CHECKS.HUAWEI_ACCOUNT as string;
        default: return strings.PICEA.VERIFY.CHECKS.UNKNOWN_CHECK as string;
    }
}

export const verifyStatusDescriber = (id: number) => {
    switch (id) {
        case VerifyStatuses.VerifyStatus_Unknown: return strings.PICEA.VERIFY.STATUSES.UNKNOWN_STATUS as string;
        case VerifyStatuses.VerifyStatus_Passed: return strings.PICEA.VERIFY.STATUSES.SUCCESS as string;
        case VerifyStatuses.VerifyStatus_Unexecuted: return strings.PICEA.VERIFY.STATUSES.NOT_PERFORMED as string;
        case VerifyStatuses.VerifyStatus_eSIMSupported: return strings.PICEA.VERIFY.STATUSES.E_SIM_UNKNOWN_STATUS as string;
        case VerifyStatuses.VerifyStatus_NotSupported: return strings.PICEA.VERIFY.STATUSES.NOT_SUPPORTED as string;
        case VerifyStatuses.VerifyStatus_SecurityLockEnabled: return strings.PICEA.VERIFY.STATUSES.LOCK_DETECTED as string;
        case VerifyStatuses.VerifyStatus_MemoryCardInserted: return strings.PICEA.VERIFY.STATUSES.MEMORY_CARD_FOUND as string;
        case VerifyStatuses.VerifyStatus_SIMCardInserted: return strings.PICEA.VERIFY.STATUSES.SIM_CARD_FOUND as string;
        case VerifyStatuses.VerifyStatus_GoogleProtectionEnabled: return strings.PICEA.VERIFY.STATUSES.GOOGLE_SECURE_DETECTED as string;
        case VerifyStatuses.VerifyStatus_FindMyEnabled: return strings.PICEA.VERIFY.STATUSES.FIND_MY_IPHONE_ACTIVATED as string;
        case VerifyStatuses.VerifyStatus_DeviceStolen: return strings.PICEA.VERIFY.STATUSES.STOLEN_DEVICE as string;
        case VerifyStatuses.VerifyStatus_AccountsFound: return strings.PICEA.VERIFY.STATUSES.ACOUNTS_DETECTED as string;
        case VerifyStatuses.VerifyStatus_DeviceStolenWarning: return strings.PICEA.VERIFY.STATUSES.SUSPECT_DEVICE as string;
        case VerifyStatuses.VerifyStatus_DeviceRooted: return strings.PICEA.VERIFY.STATUSES.ROOTED_DEVICE as string;
        case VerifyStatuses.VerifyStatus_XiaomiMiAccountEnabled: return strings.PICEA.VERIFY.STATUSES.MI_ACCOUNT_DETECTED as string;
        case VerifyStatuses.VerifyStatus_SamsungAccountEnabled: return strings.PICEA.VERIFY.STATUSES.SAMSUNG_ACCOUNT_DETECTED as string;
        case VerifyStatuses.VerifyStatus_DeviceCarrierLocked: return strings.PICEA.VERIFY.STATUSES.DEVICE_ASSIGNED_TO_OPERATOR as string;
        case VerifyStatuses.VerifyStatus_DeviceIsNotGenuine: return strings.PICEA.VERIFY.STATUSES.DEVICE_GENUINE_PROBLEM as string;
        case VerifyStatuses.VerifyStatus_DeviceEsimActivated: return strings.PICEA.VERIFY.STATUSES.E_SIM_ACTIVATION_DETECTED as string;
        case VerifyStatuses.VerifyStatus_DeviceBatteryNotGenuine: return strings.PICEA.VERIFY.STATUSES.DEVICE_BATTERY_GENUINE_PROBLEM as string;
        case VerifyStatuses.VerifyStatus_StolenCheckFailure: return definitions.verify.statusDescriber(id, "name") ?? ""; //strings.PICEA.VERIFY.STATUSES.STOLEN_CHECK_FAILURE as string;
        case VerifyStatuses.VerifyStatus_MDMProfileInstallNeeded: return strings.PICEA.VERIFY.STATUSES.MDM_NOT_INSTALLED as string;
        case VerifyStatuses.VerifyStatus_WarrantyPeriodOver: return strings.PICEA.VERIFY.STATUSES.WARRANY_EXPIRED as string;
        case VerifyStatuses.VerifyStatus_DeviceDepEnabled: return strings.PICEA.VERIFY.STATUSES.DEP_DETECTED as string;
        case VerifyStatuses.VerifyStatus_DeviceImeiNeeded: return strings.PICEA.VERIFY.STATUSES.NO_DEVICE_IMEI as string;
        case VerifyStatuses.VerifyStatus_DeviceJailbroken: return strings.PICEA.VERIFY.STATUSES.JAILBRAKE_DETECTED as string;
        case VerifyStatuses.VerifyStatus_GoogleAccountFound: return strings.PICEA.VERIFY.STATUSES.GOOGLE_ACCOUNT_DETECTED as string;
        case VerifyStatuses.VerifyStatus_HuaweiAccountEnabled: return strings.PICEA.VERIFY.STATUSES.HUAWEI_ACCOUNT_DETECTED as string;
        case VerifyStatuses.VerifyStatus_FailedMask: return strings.PICEA.VERIFY.STATUSES.MASK_ERROR as string;
        case VerifyStatuses.VerifyStatus_KnoxFrameworkFound: return strings.PICEA.VERIFY.STATUSES.KNOX_ENABLED as string;
        case VerifyStatuses.VerifyStatus_ZeroTouchDetected: return strings.PICEA.VERIFY.STATUSES.ZERO_TOUCH_ENABLED as string;
        case VerifyStatuses.VerifyStatus_StolenCheckLegal: return strings.PICEA.VERIFY.STATUSES.STOLEN_CHECK_LEGAL as string;

        default: return id.toString();
    }
}

export const getPiceaSetCasesIndexRange = (set: DiagnosticSets):number[] => {
    return getEnumValidNumberKeys(DiagnosticCases, range(16, set))
}

export const getPiceaSetIdByCaseId = (caseId: number): number => {
    const setsTo = getEnumValidNumberKeys(DiagnosticSets, range(caseId + 1))
        return Math.max(...setsTo)
}

export const eraserStateDescriber = (id: number) => {
    switch (id) {
        case 0: return strings.PICEA.ERASER.STATUSES.UNKNOWN as string;
        case 2: return strings.PICEA.ERASER.STATUSES.PREPARING as string;
        case 3: return strings.PICEA.ERASER.STATUSES.DELETING_DATA as string;
        case 4: return strings.PICEA.ERASER.STATUSES.OVERWRITING as string;
        case 5: return strings.PICEA.ERASER.STATUSES.FINALIZING as string;
        default: return id.toString();
    }
}

export const eraserSupportReasonDescriber = (id: number) => {
    switch (id) {
        case 0: return strings.PICEA.ERASER.SUPPORT_REASONS.UNKNOWN as string;
        case 1: return strings.PICEA.ERASER.SUPPORT_REASONS.NOT_SUPPORTED as string;
        case 2: return strings.PICEA.ERASER.SUPPORT_REASONS.NO_DRIVER as string;
        case 3: return strings.PICEA.ERASER.SUPPORT_REASONS.NO_ADB as string;
        case 4: return strings.PICEA.ERASER.SUPPORT_REASONS.NOT_TRUSTED as string;
        case 5: return strings.PICEA.ERASER.SUPPORT_REASONS.LOCKED as string;
        case 6: return strings.PICEA.ERASER.SUPPORT_REASONS.IOS_UNKNOWN_CONNECTIVITY_ERROR as string;
        case 255: return strings.PICEA.ERASER.SUPPORT_REASONS.NOT_READY as string;
        default: return id.toString();
    }
}

export const switchContentTypeDescriber = (type: number) => {
    switch (type) {
        case ContentType.ContentType_Contacts: return strings.CONTENT_TRANSFER.CONTENT_NAME_CONTACTS;
        case ContentType.ContentType_Calendar: return strings.CONTENT_TRANSFER.CONTENT_NAME_CALENDAR;
        case ContentType.ContentType_Messages: return strings.CONTENT_TRANSFER.CONTENT_NAME_MESSAGES;
        case ContentType.ContentType_Bookmarks: return strings.CONTENT_TRANSFER.CONTENT_NAME_BOOKMARKS;
        case ContentType.ContentType_Music: return strings.CONTENT_TRANSFER.CONTENT_NAME_MUSIC;
        case ContentType.ContentType_Photos: return strings.CONTENT_TRANSFER.CONTENT_NAME_PHOTOS;
        case ContentType.ContentType_Videos: return strings.CONTENT_TRANSFER.CONTENT_NAME_VIDEOS;
        case ContentType.ContentType_Documents: return strings.CONTENT_TRANSFER.CONTENT_NAME_DOCUMENTS;
        case ContentType.ContentType_Apps: return strings.CONTENT_TRANSFER.CONTENT_NAME_APPS;
        default: return type.toString();
    }
}